import {
  Avatar,
  Descriptions,
  Row,
  Table,
  TableColumnsType,
  Tag,
  Typography,
} from "antd";
import { getPhoto } from "src/helpers";
import Currency from "src/shared/components/currency/Currency";
import { dateFormatter } from "src/shared/config/constants";
import { UserOutlined } from "@ant-design/icons";
import { FC, useEffect, useState } from "react";
import { colNames, getCollection } from "src/collections";
import { doc, getDoc } from "firebase/firestore";
import { useMediaQuery } from "react-responsive";
import GameName from "src/shared/components/GameName/gameName";

const { Title } = Typography;

const TransactionDetails: FC<{
  players: { [x: string]: AsyncGroupPlayer & AppUserPublic };
  transactions: {
    [x: string]: BalanceTransactionV2[];
  };
}> = ({ players, transactions }) => {
  const [users, setUsers] = useState<{
    [x: string]: AsyncGroupPlayer & AppUserPublic & AppUser;
  }>({});
  useEffect(() => {
    const publicUserColRef = getCollection(colNames.appUsers);

    Object.values(players).map(async (e) => {
      const publicUserQuery = doc(publicUserColRef, e.uid);
      const snap = await getDoc(publicUserQuery);
      const data = snap.data();
      if (data) {
        setUsers((u) => ({ ...u, [e.uid]: { ...e, ...data } }));
      }
    });
  }, [players]);

  const columns: TableColumnsType<BalanceTransactionV2> = [
    {
      title: "Transaction ID",
      dataIndex: "transactionId",
      key: "transactionId",
    },
    {
      title: "Trace ID",
      dataIndex: "traceId",
      key: "traceId",
    },
    {
      title: "App User ID",
      dataIndex: "appUserUid",
      key: "appUserUid",
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (amount) => (
        <Currency value={amount} currency="cents-to-dollar" prefix="$" />
      ),
    },
    {
      title: "Bonus Cash Amount",
      dataIndex: "bonusCashAmount",
      key: "bonusCashAmount",
      render: (amount) => (
        <Currency value={amount} currency="cents-to-dollar" prefix="$" />
      ),
    },
    {
      title: "Tournament ID",
      dataIndex: "uid",
      key: "uid",
    },
    {
      title: "Transaction Date",
      dataIndex: "transactionDate",
      key: "transactionDate",
      render: (a) => dateFormatter(a),
    },
  ];
  return (
    <>
      <Title level={4}>Balance Transactions</Title>
      {Object.values(users).map((user) => (
        <div key={user.uid}>
          <Row justify="center">
            <Avatar size={70} src={getPhoto(user)}>
              <UserOutlined />
            </Avatar>
          </Row>
          <Row justify="center">
            <Title level={4}>
              {user.username}{" "}
              {user.vipStatus && user.vipStatus === "vip-1" && (
                <Tag color="green">VIP</Tag>
              )}
            </Title>
          </Row>
          <Row>
            {transactions[user.uid] && (
              <Table
                columns={columns}
                rowKey="uid"
                dataSource={transactions[user.uid]}
                scroll={{ x: 1300 }}
                className="mobile-table-large"
              />
            )}
          </Row>
        </div>
      ))}
    </>
  );
};

export const SingleTransactionDetails: FC<{
  player: AsyncGroupPlayer & AppUserPublic;
  transaction: BalanceTransaction;
}> = ({ player, transaction }) => {
  const isMobile = useMediaQuery({ query: "(max-width: 800px)" });

  const [user, setUser] = useState<
    AsyncGroupPlayer & AppUserPublic & AppUser
  >();
  useEffect(() => {
    const publicUserColRef = getCollection(colNames.appUsers);

    const publicUserQuery = doc(publicUserColRef, player.uid);
    getDoc(publicUserQuery).then((snap) => {
      const data = snap.data();
      if (data) {
        setUser({ ...player, ...data });
      }
    });
  }, [player]);

  if (!user || !transaction) return <></>;
  return (
    <>
      <Row justify="center">
        <Avatar size={70} src={getPhoto(user)}>
          <UserOutlined />
        </Avatar>
      </Row>
      <Row justify="center">
        <Title level={4}>
          {user.username}{" "}
          {user.vipStatus && user.vipStatus === "vip-1" && (
            <Tag color="green">VIP</Tag>
          )}
        </Title>
      </Row>
      <Row justify="center">
        <Descriptions
          title="Transaction Details"
          layout={!isMobile ? "horizontal" : "vertical"}
          column={1}
          bordered
          className="pb-2"
        >
          <Descriptions.Item label="ID">{transaction.uid}</Descriptions.Item>
          <Descriptions.Item label="Trace ID">
            {transaction.traceId}
          </Descriptions.Item>
          <Descriptions.Item label="App User username">
            {user.username}
          </Descriptions.Item>
          <Descriptions.Item label="App User ID">
            {transaction.appUserUid}
          </Descriptions.Item>
          <Descriptions.Item label="Type">
            <Tag color="blue">{transaction.type}</Tag>
          </Descriptions.Item>
          <Descriptions.Item label="Description">
            {transaction.description}
          </Descriptions.Item>
          <Descriptions.Item label="Amount">
            <Currency
              currency="cents-to-dollar"
              prefix="$"
              value={transaction.amount}
            />
          </Descriptions.Item>
          <Descriptions.Item label="Bonus Cash Amount">
            <Currency
              currency="cents-to-dollar"
              prefix="$"
              value={transaction.bonusCashAmount}
            />
          </Descriptions.Item>
          <Descriptions.Item label="Game">
            <GameName
              id={("gameId" in transaction && transaction.gameId) || ""}
            />
          </Descriptions.Item>
          <Descriptions.Item label="Tournament ID">
            {"tournamentId" in transaction && transaction.tournamentId}
          </Descriptions.Item>
          <Descriptions.Item label="Created Date">
            {dateFormatter(transaction.createdAt)}
          </Descriptions.Item>
        </Descriptions>
      </Row>
    </>
  );
};

export default TransactionDetails;
