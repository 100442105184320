import { FC, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "src/app/hooks";
import { Button, Row, Skeleton } from "antd";
import {
  collection,
  doc,
  documentId,
  onSnapshot,
  Query,
  query,
  where,
} from "firebase/firestore";
import { db, generateFirestorePath, isTriumphEmployee } from "src/helpers";
import { Unsubscribe } from "firebase/auth";
import {
  asyncGroupv1TournamentConverter,
  asyncGroupv3TournamentConverter,
  balanceTrxV2Converter,
} from "src/converters";
import TournamentGroupDetailsContainer from "./TournamentGroupDetails";
import {
  appUserPublicSelect,
  getAppUserPublicArrayDetails,
} from "src/features/appUsersPublic/appUserPublicSlice";
import TriumphPage from "src/shared/layout/TriumphPage";
import TournamentTimeline from "./TournamentTimeline";
import IntermediateScoreGraph from "../IntermediateScore";
import TransactionDetails, {
  SingleTransactionDetails,
} from "./transactionDetails";

const GroupTournamentDetails: FC<{
  isModal?: boolean;
  onClose?: () => void;
  tournamentId?: string;
  game?: string;
  transaction?: BalanceTransaction;
}> = ({
  tournamentId: propsTournamentId,
  game: propsGameId,
  transaction: propsTransaction,
  isModal,
  onClose,
}) => {
  const { id: paramId, game: paramGame } = useParams();

  const id = isModal && propsTournamentId ? propsTournamentId : paramId;
  const game = isModal && propsGameId ? propsGameId : paramGame;

  const [tournament, setTournament] = useState<AsyncGroupTournament>();
  const { usersPublic } = useAppSelector(appUserPublicSelect);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [userTransactions, setUserTransactions] = useState<{
    [x: string]: BalanceTransactionV2[];
  }>({});

  const { user } = useAppSelector((state) => state.userState);

  useEffect(() => {
    if (tournament) {
      const participants =
        tournament.participants.length > 0
          ? tournament.participants
          : Object.keys(
              "participantConfigs" in tournament
                ? tournament.participantConfigs
                : {}
            );

      dispatch(getAppUserPublicArrayDetails(participants));
    }
  }, [dispatch, tournament]);

  useEffect(() => {
    let unsubTournamentSnapshot: Unsubscribe | undefined;
    if (game && id) {
      const gameRef = collection(db, generateFirestorePath("games"));
      const gameDoc = doc(gameRef, game);

      const tournamentRef = collection(
        gameDoc,
        generateFirestorePath("groupTournaments")
      ).withConverter(asyncGroupv1TournamentConverter);

      const q = query(tournamentRef, where(documentId(), "==", id));
      unsubTournamentSnapshot = onSnapshot(q, (trnmnt) => {
        if (trnmnt.empty) {
          const tournamentRefv3 = collection(
            gameDoc,
            generateFirestorePath("groupTournamentsV3")
          ).withConverter(asyncGroupv3TournamentConverter);

          const qv3 = query(tournamentRefv3, where(documentId(), "==", id));
          unsubTournamentSnapshot = onSnapshot(qv3, (trnmntv3) => {
            setTournament(trnmntv3.docs[0]?.data());
          });
        } else {
          setTournament(trnmnt.docs[0]?.data());
        }
      });
    }
    return () => {
      unsubTournamentSnapshot?.();
    };
  }, [game, id]);

  const selectedUsers = useMemo(() => {
    if (!id || !tournament) return;
    let user: { [x: string]: AppUserPublic & AsyncGroupPlayer } = {};
    if (tournament.players.length > 0) {
      for (const element of tournament.players) {
        if (element.uid in usersPublic) {
          user[element.uid] = { ...usersPublic[element.uid], ...element };
        }
      }
    }
    return user;
  }, [id, tournament, usersPublic]);

  useEffect(() => {
    let unsubSnapshots: Unsubscribe[][] = [];
    const appUsersRef = collection(db, generateFirestorePath("appUsers"));
    if (tournament) {
      unsubSnapshots = tournament.participants.map((participantId) => {
        const userDoc = doc(appUsersRef, participantId);

        const userBalanceTransactionV2Ref = collection(
          userDoc,
          generateFirestorePath("balanceTransactionsV2")
        ).withConverter(balanceTrxV2Converter);

        let queryV2Ref: Query<BalanceTransactionV2>;

        if (isTriumphEmployee()) {
          queryV2Ref = query(
            userBalanceTransactionV2Ref,
            where("metadata.tournamentId", "==", tournament.uid)
          );
        } else {
          queryV2Ref = query(
            userBalanceTransactionV2Ref,
            where("metadata.tournamentId", "==", tournament.uid),
            where("orgId", "==", user?.activeOrgId)
          );
        }

        const v2snapshot = onSnapshot(queryV2Ref, (userTrxSnap) => {
          const userTrxDef: BalanceTransactionV2[] = [];
          for (const userDefSnap of userTrxSnap.docs) {
            userTrxDef.push(userDefSnap.data());
          }

          if (userTrxDef.length > 0) {
            setUserTransactions((data) => ({
              ...data,
              [userTrxDef[0].appUserUid]: userTrxDef,
            }));
          }
        });

        return [v2snapshot];
      });
    }
    return () => {
      for (const unsub of unsubSnapshots) {
        unsub[0]();
      }
    };
  }, [tournament, user?.activeOrgId]);

  if (!id || !selectedUsers) return <Skeleton paragraph title />;

  return (
    <TriumphPage removeWrapper={isModal}>
      <TournamentGroupDetailsContainer
        hideGoToTournamentButton={isModal}
        onClose={onClose}
        selectedUser={selectedUsers}
        userTransactions={userTransactions}
        tournament={tournament}
      />
      {tournament && user?.activeOrgId && (
        <IntermediateScoreGraph
          tournamentId={tournament.uid}
          gameId={tournament.gameId}
          orgId={user.activeOrgId}
        />
      )}
      {!isModal
        ? Object.keys(selectedUsers).length > 0 &&
          Object.keys(userTransactions).length > 0 && (
            <TransactionDetails
              transactions={userTransactions}
              players={selectedUsers}
            />
          )
        : propsTransaction &&
          (selectedUsers[propsTransaction.appUserUid] ||
            usersPublic[propsTransaction.appUserUid]) && (
            <SingleTransactionDetails
              player={
                selectedUsers[propsTransaction.appUserUid] ||
                usersPublic[propsTransaction.appUserUid]
              }
              transaction={propsTransaction}
            />
          )}
      {tournament && (
        <TournamentTimeline
          userTransactions={userTransactions}
          tournament={tournament}
        />
      )}
      {!isModal && (
        <Row justify="end">
          <Button type="ghost" onClick={() => navigate(-1)}>
            Go Back
          </Button>
        </Row>
      )}
    </TriumphPage>
  );
};

export default GroupTournamentDetails;
