import { Menu, MenuProps, MenuTheme } from "antd";
import React, { FC, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import { BookOutlined, TrophyOutlined, GroupOutlined } from "@ant-design/icons";
import { isTriumphEmployee } from "src/helpers";
import { useThemeSwitcher } from "src/components/theme/ThemeContextInterface";

interface Props {
  setShouldCollapse: React.Dispatch<React.SetStateAction<boolean>>;
}

const AppSideMenu: FC<Props> = ({ setShouldCollapse }) => {
  const navigate = useNavigate();

  const [selectedMenuKey, setSelectedMenuKey] = useState<string[]>([]);

  const isMobile = useMediaQuery({ query: "(max-width: 800px)" });
  const { currentTheme } = useThemeSwitcher();

  // org overview menu item key names

  const userTrxKey = "userTransactions";
  const blitzKey = "tournaments-blitz";
  const asyncGroupKey = "async-group";

  const usersTrxMenuItems = [
    // Bal Trxs
    {
      key: userTrxKey,
      label: "User Transactions",
      icon: <BookOutlined style={{ fontSize: "20px" }} />,
    },
  ];

  // Tournaments
  const tournamentTab = [
    {
      key: "tournaments",
      label: "Tournaments",
      icon: <TrophyOutlined style={{ fontSize: "20px" }} />,
      children: [
        {
          key: blitzKey,
          label: "Blitz",
        },
        {
          key: asyncGroupKey,
          label: "Async Group",
        },
      ],
    },
  ];

  // organization menu item key names
  const orgKey = "org";
  const membersKey = "members";
  const orgOverviewKey = "organization";
  const orgPhoneNumbersKey = "internalPhoneNumbers";

  const orgChild = [
    // Company
    {
      key: orgKey,
      label: "Settings",
    },
    // Members
    {
      key: membersKey,
      label: "Members",
    },
    {
      key: orgPhoneNumbersKey,
      label: "Phone Numbers",
    },
  ];

  const orgMenuItems: MenuProps["items"] = [
    {
      key: orgOverviewKey,
      label: "Organization",
      icon: <GroupOutlined style={{ fontSize: "20px" }} />,
      children: orgChild,
    },
  ];

  // navigate to appropriate component
  function handleSideMenuSelection(key: string) {
    let destination: string | null = null;

    if (key === userTrxKey) {
      destination = "/user_balance_transactions";
    } else if (key === blitzKey) {
      destination = "/tournaments/blitz";
    } else if (key === asyncGroupKey) {
      destination = "/tournaments/async-group";
    } else if (key === orgKey) {
      destination = "/organization/settings";
    } else if (key === membersKey) {
      destination = "/organization/members";
    } else if (key === orgPhoneNumbersKey) {
      destination = "/organization/internal_phone_numbers";
    }

    if (destination) {
      navigate(destination);
      setShouldCollapse(isMobile && true);
      setSelectedMenuKey([key]);
    }
  }

  return (
    <>
      <Menu
        className="side-menu text-disable-button"
        mode="inline"
        theme={currentTheme as MenuTheme}
        items={[
          ...(isTriumphEmployee() ? usersTrxMenuItems : []),
          ...(isTriumphEmployee() ? tournamentTab : []),
          ...orgMenuItems,
        ]}
        selectedKeys={selectedMenuKey}
        onClick={(e) => {
          handleSideMenuSelection(e.key);
        }}
      ></Menu>
    </>
  );
};

export default AppSideMenu;
