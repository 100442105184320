import { FC, useEffect, useState } from "react";
import { Navigate, useParams } from "react-router-dom";
import {
  getAuth,
  isSignInWithEmailLink,
  signInWithEmailLink,
} from "firebase/auth";
import { Logger } from "src/helpers";
import { fetchUpsertUser } from "src/features/authentication/userSlice";
import { useAppDispatch, useAppSelector } from "src/app/hooks";
import LoadingTriumph from "./Loading";

const MagicLink: FC = () => {
  const [error, setError] = useState(false);
  const { email } = useParams();
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.userState);

  useEffect(() => {
    const auth = getAuth();
    let userEmail: string | null | undefined = email;
    if (userEmail) {
      if (isSignInWithEmailLink(auth, window.location.href)) {
        if (!userEmail) {
          userEmail = window.prompt(
            "Please provide your email for confirmation"
          ) as string;
        }
        signInWithEmailLink(auth, userEmail, window.location.href)
          .then(async (result) => {
            const user = result.user.toJSON() as FirebaseAuthUser;
            await dispatch(fetchUpsertUser(user));
          })
          .catch((error) => {
            setError(true);
            Logger("MagicLink.tsx", "during signing", error);
          });
      }
    }
  }, [dispatch, email]);

  if (error) {
    if (user) return <Navigate to="/" />;

    return (
      <LoadingTriumph
        isLoading={false}
        noLoadingText="Link is broken or expired, Please try again."
      />
    );
  }

  if (user) {
    return <Navigate to="/" />;
  }
  return <LoadingTriumph isLoading />;
};

export default MagicLink;
