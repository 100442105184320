import { FC } from "react";
import { getRandomColor } from "src/helpers";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import "chart.js/auto";
import { Pie, Doughnut } from "react-chartjs-2";
import { useAppSelector } from "src/app/hooks";
import { Col, Row, Typography } from "antd";

ChartJS.register(ArcElement, Tooltip, Legend);

type Props = {
  data: UserStatisticsAmountsResponse["paidGamesStats"];
  totalAsync: number;
  totalBlitz: number;
  totalGames: number;
};
const FraudGraph: FC<Props> = ({
  data,
  totalAsync,
  totalBlitz,
  totalGames,
}) => {
  const { listOfAllGames } = useAppSelector((state) => state.gameState);

  const doughnutData = {
    labels: data.map((e) => {
      return listOfAllGames.find((f) => f.id === e.game_id)?.name || e.game_id;
    }),
    datasets: [
      {
        data: data.map((e) => e.total_games_played),
        borderColor: data.map(() => getRandomColor()),
        backgroundColor: data.map(() => getRandomColor()),
        fill: false,
        tension: 0.4,
        label: "Games Data",
        hoverOffset: 4,
      },
    ],
  };

  const pieData = {
    labels: [
      `Async Tournaments (${totalAsync})`,
      `Blitz Tournaments (${totalBlitz})`,
    ],
    datasets: [
      {
        data: [totalAsync, totalBlitz],
        borderColor: [getRandomColor(), getRandomColor()],
        backgroundColor: [getRandomColor(), getRandomColor()],
        fill: false,
        tension: 0.4,
        label: "Games Played",
        hoverOffset: 4,
      },
    ],
  };

  return (
    <>
      <Typography.Text>Total Games Played (Paid): {totalGames}</Typography.Text>
      <Row justify="center">
        <Col span={10}>
          <Pie data={pieData} />
        </Col>
        <Col span={10}>
          <Doughnut data={doughnutData} />
        </Col>
      </Row>
    </>
  );
};

export default FraudGraph;
