import { Button, Col, Form, Input, Modal, Row, Select, Tag } from "antd";
import { Unsubscribe } from "firebase/auth";
import {
  collection,
  doc,
  onSnapshot,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { FC, useEffect, useState } from "react";
import { gameConverter, orgConverter } from "src/converters";
import { axiosInstanceV2, db } from "src/helpers";
import { validateNumericValue } from "src/shared/config/constants";

interface Props {
  config: InternalPhoneNumber | null;
  onClose: () => void;
}
const InternalPhoneNumbersForm: FC<Props> = ({ config, onClose }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [form] = Form.useForm();
  const [orgs, setOrgs] = useState<
    {
      value: string;
      label: string;
    }[]
  >([]);
  const [currOrgId, setCurrOrgId] = useState<string | null>(null);
  const [games, setGames] = useState<
    {
      value: string;
      label: string;
    }[]
  >([]);
  const [isTouched, setIsTouched] = useState<{
    [c: string]: boolean;
  }>({});

  useEffect(() => {
    let unsubOrgs: Unsubscribe;
    const orgsRef = collection(db, "organizations").withConverter(orgConverter);

    unsubOrgs = onSnapshot(orgsRef, (colSnap) => {
      const orgCol: typeof orgs = [];
      for (const snap of colSnap.docs) {
        const id = snap.id;
        const data = snap.data();
        orgCol.push({
          value: id,
          label: data.name,
        });
      }
      setOrgs(orgCol);
    });
    return () => {
      unsubOrgs?.();
    };
  }, []);

  useEffect(() => {
    let unsubGames: Unsubscribe;
    const gamesRef = query(
      collection(db, "games").withConverter(gameConverter),
      where("orgId", "==", currOrgId)
    );

    if (currOrgId) {
      unsubGames = onSnapshot(gamesRef, (colSnap) => {
        const gamesCol: typeof games = [];
        for (const snap of colSnap.docs) {
          const id = snap.id;
          const data = snap.data();
          gamesCol.push({
            value: id,
            label: data.displayName,
          });
        }
        setGames(gamesCol);
      });
    }

    return () => {
      unsubGames?.();
    };
  }, [currOrgId]);

  useEffect(() => {
    if (config) {
      setIsOpen(true);
      form.setFieldsValue(config);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [config]);

  function onDialogClose() {
    onClose();
    setError("");
    setIsLoading(false);
    form.resetFields();
    setCurrOrgId(null);
    setIsOpen(false);
  }

  async function onSave() {
    setError("");
    const isUndefined =
      Object.values(form.getFieldsValue()).findIndex(
        (f) => f === undefined || f === null
      ) !== -1;

    const isError = form.getFieldsError().find((f) => f.errors.length > 0);
    if (isUndefined || isError) {
      setError("Please fill all fields.");
      return;
    }
    setIsLoading(true);
    const internalPhoneNumbersRef = collection(db, "internalPhoneNumbers");

    const data = form.getFieldsValue();
    const formData = {
      phoneNumber: data.phoneNumber,
      code: data.code,
      orgId: data.orgId,
      gameId: data.gameId,
    };

    if (!config) {
      // add
      try {
        await axiosInstanceV2.post<{
          internalPhoneNumber: InternalPhoneNumber;
        }>("/internal/users/create_test_user", formData);
        onDialogClose();
      } catch (error) {
        const errorMessage = (error as any)?.["response"]?.["data"]?.["error"];
        setError(errorMessage || "Unknown Error");
        console.error(error);
      }
      setIsLoading(false);
    } else {
      // edit
      const internalPhoneNumberQuery = doc(internalPhoneNumbersRef, config.uid);

      await updateDoc(internalPhoneNumberQuery, formData);
      setIsLoading(false);

      onDialogClose();
    }
  }

  function onChange(
    name: keyof InternalPhoneNumber,
    value: string | number | null
  ) {
    form.setFieldsValue({
      ...form.getFieldsValue(),
      [name]: value,
    });
    if (config) {
      setIsTouched({
        ...isTouched,
        [name]: form.getFieldValue(name) !== config[name],
      });
    }
  }

  return (
    <>
      <Button
        onClick={() => {
          form.resetFields();
          setIsOpen(true);
        }}
        type="primary"
      >
        Add Internal Phone Number
      </Button>
      <Modal
        title={`${!config ? "Add" : "Edit"} Internal Phone Number`}
        centered
        open={isOpen}
        onOk={onSave}
        okButtonProps={{
          disabled: config
            ? Object.values(isTouched).findIndex((f) => f === true) === -1
            : false,
        }}
        onCancel={onDialogClose}
        destroyOnClose
        confirmLoading={isLoading}
      >
        {isOpen && (
          <Form
            name="Internal Phone Number"
            className="spaced-form"
            form={form}
            autoComplete="off"
          >
            <Row>
              <Col span={24}>
                <Form.Item
                  initialValue={config?.phoneNumber}
                  label="Phone Number"
                  className="app-config-input-wrap"
                  name="phoneNumber"
                  rules={[
                    {
                      required: true,
                      message: "Phone Number is required",
                      validator: validateNumericValue,
                    },
                  ]}
                >
                  <Input
                    name="phoneNumber"
                    className="input-p-10"
                    size="middle"
                    onChange={(e) => onChange("phoneNumber", e.target.value)}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  initialValue={config?.code}
                  label="Code"
                  className="app-config-input-wrap"
                  name="code"
                  rules={[
                    {
                      required: true,
                      message: "Code is required",
                      validator: validateNumericValue,
                    },
                  ]}
                >
                  <Input
                    name="code"
                    className="input-p-10"
                    size="middle"
                    onChange={(e) => onChange("code", e.target.value)}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  initialValue={config?.code}
                  label="Organization"
                  className="app-config-input-wrap"
                  name="orgId"
                  rules={[
                    {
                      required: true,
                      message: "Organization is required",
                      validator: validateNumericValue,
                    },
                  ]}
                >
                  <Select
                    options={orgs}
                    className="balanced-padded-select"
                    showSearch
                    filterOption={(input, option) => {
                      const isPresent = option?.label
                        .toLowerCase()
                        .includes(input.toLowerCase());

                      return !!isPresent;
                    }}
                    onSelect={(value: string | number | null) => {
                      onChange("orgId", value);
                      if (typeof value === "string" || value == null) {
                        setCurrOrgId(value);
                      }
                    }}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  initialValue={config?.code}
                  label="Game"
                  className="app-config-input-wrap"
                  name="gameId"
                  rules={[
                    {
                      required: true,
                      message: "Game is required",
                      validator: validateNumericValue,
                    },
                  ]}
                >
                  <Select
                    showSearch
                    className="balanced-padded-select"
                    filterOption={(input, option) => {
                      const isPresent = option?.label
                        .toLowerCase()
                        .includes(input.toLowerCase());

                      return !!isPresent;
                    }}
                    disabled={currOrgId == null}
                    options={games}
                  />
                </Form.Item>
              </Col>
            </Row>
            {error && <Tag color="warning">{error}</Tag>}
          </Form>
        )}
      </Modal>
    </>
  );
};

export default InternalPhoneNumbersForm;
