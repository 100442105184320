import { FC, useMemo, useState } from "react";
import {
  Collapse,
  Divider,
  Row,
  Tag,
  Descriptions,
  Typography,
  Skeleton,
  Table,
  TableColumnsType,
  Button,
  Modal,
  message,
} from "antd";
import {
  CaretRightOutlined,
  LoadingOutlined,
  BulbOutlined,
  ClockCircleOutlined,
} from "@ant-design/icons";
import { dateFormatter } from "src/shared/config/constants";
import Currency from "src/shared/components/currency/Currency";
import { useMediaQuery } from "react-responsive";
import TriumphPage from "src/shared/layout/TriumphPage";
import { useNavigate } from "react-router-dom";
import { axiosInstanceV2, isTriumphEmployee } from "src/helpers";
import ReplayVideo from "../ReplayVideo";
import UserInfoExpanded from "src/components/balanceTransactions/userInfo/UserInfoExpanded";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { useAppSelector } from "src/app/hooks";

const { Title, Link } = Typography;
const { Panel } = Collapse;

type Props = {
  tournament?: AsyncGroupTournament;
  selectedUser?: { [x: string]: AppUserPublic & AsyncGroupPlayer };
  userTransactions: {
    [x: string]: BalanceTransactionV2[];
  };
  hideGoToTournamentButton?: boolean;
  onClose?: () => void;
};
const TournamentGroupDetailsContainer: FC<Props> = ({
  tournament,
  selectedUser,
  userTransactions,
  hideGoToTournamentButton,
}) => {
  const navigate = useNavigate();

  const [messageApi, contextHolder] = message.useMessage();
  const { user } = useAppSelector((state) => state.userState);

  const [userModal, setUserModal] = useState<string | null>(null);
  const statusTag = useMemo(() => {
    if (!tournament) return;
    const { status } = tournament;
    switch (status) {
      case "finished":
        return {
          color: "green",
          tag: "Finished",
          icon: <BulbOutlined />,
          hideResult: false,
        };
      case "in-progress":
        return {
          color: "processing",
          tag: "In Progress",
          icon: <LoadingOutlined />,
          hideResult: true,
        };
      case "waiting-to-match":
        return {
          color: "warning",
          tag: "Waiting to Match",
          icon: <ClockCircleOutlined />,
          hideResult: true,
        };
      default:
        return { color: "purple", tag: status };
    }
  }, [tournament]);

  const isMobile = useMediaQuery({ query: "(max-width: 800px)" });
  if (!tournament || !selectedUser) return <Skeleton title paragraph />;

  const columns: TableColumnsType<AppUserPublic & AsyncGroupPlayer> = [
    {
      title: "Username",
      dataIndex: "username",
      key: "username",
      width: 130,
      render: (data, record) => (
        <Link
          className="ant-typography"
          onClick={() => {
            setUserModal(record.uid);
          }}
        >
          {data}
        </Link>
      ),
    },
    {
      title: "User ID",
      dataIndex: "uid",
      key: "uid",
      width: 130,
    },
    {
      title: "Matched At",
      dataIndex: "createdAt",
      key: "createdAt",
      width: 130,
      render: (id, record) => dateFormatter(record.createdAt),
    },
    {
      title: "Score",
      dataIndex: "finalScore.value",
      key: "finalScore.value",
      width: 130,
      render: (id, record) =>
        record.finalScore ? record.finalScore.value : "-",
    },
    {
      title: "Score Submitted At",
      dataIndex: "finalScore.createdAt",
      key: "finalScore.createdAt",
      width: 130,
      render: (id, record) =>
        record.finalScore ? dateFormatter(record.finalScore.createdAt) : "-",
    },
    {
      title: "Replay",
      dataIndex: "uid",
      key: "replay",
      width: 130,
      render: (id, record) => (
        <ReplayVideo
          replay={tournament.replays[record.uid]}
          username={record.username}
        />
      ),
    },
    {
      title: "Place",
      dataIndex: "place",
      key: "place",
      width: 130,
      defaultSortOrder: "ascend",
      sorter: (a, b) =>
        (tournament?.results?.[a.uid]?.place || 0) -
        (tournament?.results?.[b.uid]?.place || 0),
      render: (id, record) => tournament?.results?.[record.uid]?.place || "-",
    },
    {
      title: "Elo V4",
      dataIndex: "uid",
      key: "uid",
      width: 130,
      render: (id, record) =>
        tournament.players.find((f) => f.uid === record.uid)?.eloV4,
    },
    {
      title: "Status",
      dataIndex: "Status",
      key: "Status",
      width: 130,
      render: (id, record) => {
        if (tournament.status === "finished") {
          const isWinner = Boolean(tournament.results?.[record.uid]?.won);
          return (
            <Tag color={isWinner ? "green" : "red"}>
              {isWinner ? "Winner" : "Loser"}
            </Tag>
          );
        }
        return "-";
      },
    },
    {
      title: "Payout",
      dataIndex: "Payout",
      key: "Payout",
      width: 130,
      render: (id, record) => (
        <Currency
          value={tournament?.results?.[record.uid]?.payout}
          currency="cents-to-dollar"
          prefix="$"
        />
      ),
    },
    {
      title: "Payout BonusCash",
      dataIndex: "payoutBonusCash",
      key: "payoutBonusCash",
      width: 130,
      render: (id, record) =>
        tournament?.results?.[record.uid]?.payoutBonusCash || "-",
    },
    {
      title: "Claimed At",
      dataIndex: "claimedAt",
      key: "claimedAt",
      width: 130,
      render: (id, record) =>
        tournament?.results?.[record.uid]?.claimedAt
          ? dateFormatter(tournament?.results?.[record.uid]?.claimedAt)
          : "Not Claimed",
    },
    {
      title: "System Reported",
      dataIndex: "uid",
      key: "uid",
      width: 130,
      render: (id, record) =>
        record.finalScore?.systemReported ? "Yes" : "No",
    },
  ];

  if (isTriumphEmployee()) {
    columns.push({
      title: "Action",
      dataIndex: "uid",
      key: "uid",
      width: 130,
      fixed: "right",
      render: (id: string) => (
        <Button
          disabled={
            !!userTransactions[id]?.find(
              (f) => f.type === "reverse-start-group-tournament"
            )
          }
          onClick={() =>
            Modal.confirm({
              title: `Confirm Recredit ?`,
              icon: <ExclamationCircleOutlined />,
              content: `By clicking confirm the player will get a recredit`,
              okText: "Confirm",
              cancelText: "Back",
              onOk: () => onRecredit(id),
              okCancel: true,
            })
          }
        >
          Re Credit
        </Button>
      ),
    });
  }

  async function onRecredit(id: string) {
    if (!tournament) {
      messageApi.open({
        type: "error",
        content: "Something went wrong!",
      });
      return;
    }
    try {
      const response = await axiosInstanceV2.post(`/admin/refund_tournament`, {
        userId: id,
        tournamentId: tournament.uid,
        gameId: tournament.gameId,
        orgId: user?.activeOrgId,
      });

      if (response.status === 200 && response.data) {
        messageApi.open({
          type: "success",
          content: "Success!",
        });
      } else {
        messageApi.open({
          type: "error",
          content: "Something went wrong!",
        });
      }
    } catch (error) {
      messageApi.open({
        type: "error",
        content: "Something went wrong!",
      });
    }
  }

  const renderCollapse = () => {
    return (
      <>
        {"participantConfigs" in tournament &&
          Object.keys(tournament.participantConfigs).length > 0 && (
            <Title level={3}>Player Buy Ins</Title>
          )}

        <Collapse
          bordered={false}
          defaultActiveKey={["1"]}
          expandIcon={({ isActive }) => (
            <CaretRightOutlined rotate={isActive ? 90 : 0} />
          )}
          className="site-collapse-Descriptions.-collapse"
        >
          {"participantConfigs" in tournament &&
            Object.entries(tournament.participantConfigs).map(
              ([uid, config]) => (
                <Panel
                  header={
                    <Descriptions>
                      <Descriptions.Item label="User ID">
                        {uid}
                      </Descriptions.Item>
                    </Descriptions>
                  }
                  key={uid}
                  className="site-collapse-Descriptions.-panel"
                >
                  <Descriptions
                    layout={!isMobile ? "horizontal" : "vertical"}
                    colon
                    column={1}
                    bordered
                  >
                    <Descriptions.Item label="Entry Price">
                      <Currency
                        currency="cents-to-dollar"
                        prefix="$"
                        value={-config.entryPrice}
                      />
                    </Descriptions.Item>

                    <Descriptions.Item label="Size">
                      {config.size}
                    </Descriptions.Item>

                    <Descriptions.Item label="Entry Gems">
                      {config.entryGems}
                    </Descriptions.Item>
                    <Descriptions.Item label="Priority">
                      {config.priority}
                    </Descriptions.Item>
                  </Descriptions>
                  <div className="mb-2" />
                  <Title level={5}>Rewards</Title>

                  <Table
                    pagination={{ hideOnSinglePage: true, pageSize: 1000 }}
                    columns={[
                      {
                        title: "Payout",
                        dataIndex: "payout",
                        key: "payout",
                      },
                      {
                        title: "Payout Bonus Cash",
                        dataIndex: "payoutBonusCash",
                        key: "payoutBonusCash",
                      },
                      {
                        title: "Payout Gems",
                        dataIndex: "payoutGems",
                        key: "payoutGems",
                      },
                    ]}
                    rowKey="id"
                    dataSource={config.rewards}
                    className="mobile-table-large"
                  />
                </Panel>
              )
            )}
        </Collapse>
      </>
    );
  };

  return (
    <TriumphPage>
      {contextHolder}

      <UserInfoExpanded user={userModal} onClose={() => setUserModal(null)} />

      {!hideGoToTournamentButton && (
        <Button
          type="primary"
          onClick={() => navigate("/tournaments/async-group")}
        >
          Return to Tournaments
        </Button>
      )}
      <Row justify="center" className="pb-2">
        <Tag
          className="tournament-status-tag"
          icon={statusTag?.icon}
          color={statusTag?.color}
        >
          {statusTag?.tag}
        </Tag>
      </Row>
      <Row>
        <Title level={4}>Players</Title>
      </Row>
      <Table
        columns={columns}
        loading={!selectedUser}
        rowKey="id"
        dataSource={Object.entries(selectedUser || {}).map(([id, user]) => ({
          id,
          ...user,
        }))}
        scroll={{ x: 1300 }}
        className="mobile-table-large"
      />

      <Divider />
      <Descriptions
        title="Tournament Details"
        layout={!isMobile ? "horizontal" : "vertical"}
        colon
        column={1}
        bordered
        className="pb-2"
      >
        <Descriptions.Item label="RNG">{tournament.RNG}</Descriptions.Item>
        <Descriptions.Item label="Status">
          <Tag color="geekblue">{tournament.status}</Tag>
        </Descriptions.Item>

        <Descriptions.Item label="Participants Max">
          {tournament.participantsMax}
        </Descriptions.Item>
        <Descriptions.Item label="Participants Size">
          {tournament.participantsSize}
        </Descriptions.Item>
        <Descriptions.Item label="Created At">
          {dateFormatter(tournament.createdAt)}
        </Descriptions.Item>
        <Descriptions.Item label="Last Matched At">
          {dateFormatter(tournament.lastMatchedAt)}
        </Descriptions.Item>
        <Descriptions.Item label="Finished At">
          {dateFormatter(tournament.finishedAt)}
        </Descriptions.Item>
      </Descriptions>
      <Divider />

      {renderCollapse()}
      <Divider />
    </TriumphPage>
  );
};

export default TournamentGroupDetailsContainer;
